import React, {useCallback, useMemo} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import routes from 'config/routes';
import {CACHE_KEYS} from 'constants/cache-keys';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {useFetcher} from 'hooks/use-fetcher';
import {getWidgetsCustomizations} from 'services/widgets-customization';
import useElementTranslation from 'components/ClimateWidgets/Metering/hooks/use-element-translation';
import useMeteringElements from 'components/ClimateWidgets/Metering/hooks/use-metering-elements';
import {
  WidgetGroup as IWidgetGroup,
  WidgetGroupsData,
} from 'types/WidgetCustomization';
import {METERING_ELEMENTS} from 'types/Metering';

import InfoPage from 'components/InfoPage/InfoPage';
import ListItem from 'components/Data/ListItem';
import WidgetGroup from './WidgetGroup';

const Wrapper = styled.div`
  // height: 100%;
`;

const WidgetsCustomizationPage = () => {
  const history = useHistory();
  const {search} = useLocation();
  const {translate} = useTranslations();
  const elementTranslate = useElementTranslation({});
  const {getTokenText} = useTokenTranslation();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});
  const {meteringElements} = useMeteringElements();

  const {data: widgetGroupsData, updateDataById} = useFetcher<
    WidgetGroupsData[]
  >({
    fetcher: getWidgetsCustomizations,
    key: CACHE_KEYS.WIDGETS_CUSTOMIZATION,
    initialValue: [],
  });

  const widgetGroups = useMemo(() => {
    const tokenTranslations = getTokenText(2);

    return widgetGroupsData
      .filter(({group}) => translate(`sdk.web.widgets.custom.${group}.title`))
      .map((widgetGroup) => {
        const {group, types} = widgetGroup;

        return {
          group,
          title: translate(`sdk.web.widgets.custom.${group}.title`),
          subtitle: translate(`sdk.web.widgets.custom.${group}.subtitle`),
          types: types.map((widget) => {
            const {type, enabled} = widget;
            const element = type.replace('metering_', '') as METERING_ELEMENTS;
            const banner = meteringElements.find(
              (meteringElement) => meteringElement.element === element,
            )?.banner;

            return {
              type,
              enabled,
              title: translate(`sdk.web.widgets.custom.${type}.title`),
              prompts: {
                disable: {
                  title: translate(
                    `sdk.web.widgets.custom.${group}.disable.title`,
                  ),
                  text: elementTranslate(
                    `sdk.web.widgets.custom.${group}.disable.text`,
                    [
                      {
                        key: '{token}',
                        value: tokenTranslations,
                      },
                    ],
                    {
                      element,
                    },
                  ),
                  banner,
                },
                disableSuccess: {
                  title: translate(
                    `sdk.web.widgets.custom.${group}.disable.success.title`,
                  ),
                  text: elementTranslate(
                    `sdk.web.widgets.custom.${group}.disable.success.text`,
                    [
                      {
                        key: '{token}',
                        value: tokenTranslations,
                      },
                    ],
                    {
                      element,
                    },
                  ),
                  banner,
                },
                enable: {
                  title: translate(
                    `sdk.web.widgets.custom.${group}.enable.title`,
                  ),
                  text: elementTranslate(
                    `sdk.web.widgets.custom.${group}.enable.text`,
                    [
                      {
                        key: '{token}',
                        value: tokenTranslations,
                      },
                    ],
                    {
                      element,
                    },
                  ),
                  cta: translate(`sdk.web.widgets.custom.${group}.enable.cta`),
                  onSuccess() {
                    history.push(
                      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.METERING.href,
                    );
                  },
                  banner,
                },
              },
            };
          }),
        };
      });
  }, [
    translate,
    elementTranslate,
    getTokenText,
    meteringElements,
    widgetGroupsData,
    history,
  ]);

  const widgetGroup = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const widgetGroup = searchParams.get('group');

    return widgetGroups.find(({group}) => group === widgetGroup);
  }, [widgetGroups, search]);

  const handleWidgetSelect = useCallback(
    ({group}: {group: string}) => {
      history.push(
        `${routes.SETTINGS.WIDGETS_CUSTOMIZATION.WIDGET.href}?group=${group}`,
      );
    },
    [history],
  );

  const handleWidgetUpdate = useCallback(
    (data: IWidgetGroup) => {
      updateDataById({
        idKey: 'group',
        id: data.group,
        updates: data,
      });
    },
    [updateDataById],
  );

  return (
    <Switch>
      <Route path={routes.SETTINGS.WIDGETS_CUSTOMIZATION.WIDGET.href}>
        <WidgetGroup widgetGroup={widgetGroup!} onUpdate={handleWidgetUpdate} />
      </Route>
      <Route>
        <InfoPage
          title={translate('sdk.web.widgets.custom.title')}
          onClose={handleGoBack}>
          <Wrapper>
            {widgetGroups.map((widget) => (
              <div key={widget.group}>
                <ListItem
                  onClick={() => handleWidgetSelect({group: widget.group})}>
                  <b>{widget.title}</b>
                </ListItem>
              </div>
            ))}
          </Wrapper>
        </InfoPage>
      </Route>
    </Switch>
  );
};
export default WidgetsCustomizationPage;
