import {useMemo} from 'react';

import {ElementData, METERING_ELEMENTS} from 'types/Metering';
import useElementTranslation from '../../hooks/use-element-translation';

import {ALT_CONSUMPTION_UNITS} from 'components/ClimateWidgets/Metering/components/Consumption/utils';
import {getUnitString} from 'components/ClimateWidgets/Metering/utils';

interface UseMeterUnitsProps {
  element: METERING_ELEMENTS;
  elementData: ElementData;
}

export const useAltUnits = (props: UseMeterUnitsProps) => {
  const {element, elementData} = props;
  const translate = useElementTranslation({element});

  return useMemo(
    () => ({
      unit: translate(`sdk.web.meter.unit.${getUnitString({element})}`),
      altUnit: translate(`sdk.web.meter.unit.${elementData.consumptionUnit}`),
      showAltUnit:
        elementData.consumptionUnit &&
        ALT_CONSUMPTION_UNITS.includes(elementData.consumptionUnit),
    }),
    [translate, elementData, element],
  );
};
