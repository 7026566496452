import React, {useMemo} from 'react';
import {ElementData, METERING_ELEMENTS, METERING_UNITS} from 'types/Metering';
import {formatNumber} from 'utils/numbers';
import {useAltUnits} from 'components/ClimateWidgets/Metering/components/Consumption/hooks';
import {baseUnitToUnit} from 'components/ClimateWidgets/Metering/components/Consumption/utils';

interface ConsumptionValueProps {
  element: METERING_ELEMENTS;
  elementData: ElementData;
  value: number;
}

const ConsumptionValue = (props: ConsumptionValueProps) => {
  const {element, elementData, value} = props;
  const {unit, altUnit, showAltUnit} = useAltUnits({element, elementData});

  const {formattedValue, formattedAltValue} = useMemo(
    () => ({
      formattedValue: formatNumber({
        number: value,
        dp: 3,
        ignoreThousandRule: true,
      }),
      formattedAltValue: baseUnitToUnit({
        value,
        dp: 3,
        unit: elementData.consumptionUnit || METERING_UNITS.KWH,
      }),
    }),
    [value, elementData],
  );

  return (
    <b>
      {showAltUnit && (
        <>
          <b>
            {formattedAltValue} {altUnit}
            <br />
          </b>
        </>
      )}
      <span style={showAltUnit ? {fontWeight: 'normal'} : undefined}>
        {showAltUnit && '('}
        {formattedValue} {unit}
        {showAltUnit && ')'}
      </span>
    </b>
  );
};

export default ConsumptionValue;
