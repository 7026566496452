export type ElectricityProvider = {
  id: number;
  name: string;
};

export type MeteringStats = {
  goal: number;
  current_consumption: number;
  relative_performance: number;
  projected_recoins: number;
  projected_savings_percentage: number;
  recoins_earned: number;
  current_day_consumption: number;
  connected: boolean;
  has_settings: boolean;
  has_current_month_consumption: boolean;
  has_previous_month_consumption: boolean;
  connect_date: string;
  has_previous_results: boolean;
  last_reading: null;
  last_reading_date: null;
};

export enum METERING_ELEMENTS {
  ELECTRICITY = 'electricity',
  WATER = 'water',
  GAS = 'gas',
}

export type ElementData = {
  element: METERING_ELEMENTS;
  icon: string;
  summaryIcon?: string;
  resultsIcon: string;
  banner: string;
  successBanner: string;
  progressPathColor: string;
  consumptionUnit?: METERING_UNITS;
  displayConsumptionUnit?: boolean;
  showWarning?: boolean;
};

export type AllMeteringStats = Record<METERING_ELEMENTS, MeteringStats>;

export type PreviousResultsData = {
  year: {
    total_consumption: number;
    total_savings: number;
    recoins_earned: number;
    relative_performance: number;
  };
  months: [
    {
      month: string;
      consumption: number;
      goal: number;
      savings: number;
      recoins_earned: number;
      until_next_recoin: number;
      relative_performance: number;
      user_meter_id: number;
    },
  ];
};

export enum METERING_UNITS {
  KWH = 'kwh',
  LITRE = 'litre',
  MWH = 'mwh',
  METER = 'meter', // REVISIT WATER METER NAMING
  METER_GAS = 'meter.gas',
}
