import {METERING_ELEMENTS, METERING_UNITS} from 'types/Metering';
import {formatNumber} from 'utils/numbers';

const UNITS_BASE_FACTOR = {
  [METERING_UNITS.LITRE]: 1000,
  [METERING_UNITS.METER]: 1000,
  [METERING_UNITS.METER_GAS]: 10,
  [METERING_UNITS.MWH]: 1000,
  [METERING_UNITS.KWH]: 1,
};

export const ALT_CONSUMPTION_UNITS = [
  METERING_UNITS.METER,
  METERING_UNITS.MWH,
  METERING_UNITS.METER_GAS,
];

interface ConversionParams {
  value: number;
  unit: METERING_UNITS;
  formatResults?: boolean;
  dp?: number;
}

export const unitToBaseUnit = ({unit, value}: ConversionParams) =>
  Math.round(value * UNITS_BASE_FACTOR[unit]);

export const baseUnitToUnit = ({
  unit,
  value,
  dp = 3,
  formatResults = true,
}: ConversionParams) => {
  const results = value / UNITS_BASE_FACTOR[unit];

  return formatResults
    ? formatNumber({
        dp,
        number: results,
        ignoreThousandRule: true,
      })
    : results.toFixed(dp);
};

interface NormalizedConsumptionParams {
  value: number;
  unit?: METERING_UNITS;
  element: METERING_ELEMENTS;
  formatResults?: boolean;
  ignoreThousandRule?: boolean;
  dp?: number;
}

export const normalizeConsumption = ({
  value,
  unit,
  formatResults,
  ignoreThousandRule,
  dp,
}: NormalizedConsumptionParams) => {
  return unit && ALT_CONSUMPTION_UNITS.includes(unit)
    ? baseUnitToUnit({
        value,
        unit,
        formatResults,
        dp,
      }).toString()
    : formatResults
    ? formatNumber({
        number: value,
        dp: 3,
        ignoreThousandRule: true,
      })
    : value;
};

interface SumConsumptionValues {
  integerValue: number | string;
  decimalValue: number | string;
}

export const sumConsumptionValues = ({
  integerValue,
  decimalValue,
}: SumConsumptionValues) => Number(`${integerValue}.${decimalValue}`);
